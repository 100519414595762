<template>

	<div :id="container_id" class="container" :class="get_classes()"><div>

		<header class="container-head" :class="{ 'header-toggle' : header_toggle }" v-on:click="( header_toggle ) ? show = !show : false">

			<div class="head-main">
				<slot name="container-head"></slot>
			</div>

			<div class="head-actions" v-if="!screenshot && !$slots['head-actions']">

				<button @click="screenshot_function" v-if="screenshot_function">
					<font-awesome-icon :icon="[ 'fas', 'download' ]" />
				</button>

				<button @click="tweet" v-if="tweet_content">
					<font-awesome-icon :icon="[ 'fab', 'twitter' ]" />
				</button>

				<button class="toggle" v-on:click="show = !show"></button>

			</div>

			<div class="custom-actions head-actions" v-if="!screenshot && $slots['head-actions']">
				<slot name="head-actions"></slot>
			</div>

		</header>

		<div class="container-body" v-if="show">
			<slot name="container-body"></slot>
		</div>

	</div></div>

</template>

<script>

	import { library } from '@fortawesome/fontawesome-svg-core'
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

	import { faDownload } from '@fortawesome/free-solid-svg-icons'
	import { faTwitter } from '@fortawesome/free-brands-svg-icons'

	library.add( faDownload, faTwitter )

	export default {
		
		name	: 'container',

		mixins	: [],
		
		components	: {
			'font-awesome-icon'	: FontAwesomeIcon,
		},

		props	: [

			'container_id',	// ID du conteneur
			'classes',	// Classes
			'title',	// Titre du bloc
			'collapse_on_mount',	// Ouverture au chargement
			'header_toggle',	// Toggle activé/désactivé au clic sur le header
			'screenshot',	// Screenshot en cours
			'screenshot_filename',	// Nom du fichier du screenshot
			'screenshot_function',	// Fonction screenshot
			'tweet_content',

		],

		data	: function() { return {

			screenshot_mode	: false,	// Screenshot en cours
			show	: false,	// Contenu affiché/caché

		}},

		methods	: {

			/**
			 */

			get_classes	: function() {

				return Object.assign({}, {
					noheader	: false,
					maximized	: ( this.show || this.screenshot_mode ),
					minimized	: ( !this.show && !this.screenshot_mode ),
					screenshot	: this.screenshot_mode,
				}, this.classes )

			},

			/**
			 * Tweet
			 */

			tweet	: function() {

				var url	= 'https://twitter.com/share?url=' + encodeURIComponent( 'cc @TTFLab' ) + '&text='

				url	+= encodeURIComponent( this.tweet_content )

				window.open( url, '_blank' )

			},

		},

		watch	: {

			/**
			 * Permet d'afficher le contenu du conteneur quoi qu'il arrive lors d'un screenshot
			 */

			screenshot() {
				this.show	= this.screenshot
			}

		},

		computed	: {
		},

		mounted	: function() {
			
			this.show	= !this.collapse_on_mount
			this.screenshot_mode	= this.screenshot ? true : false

		},

}

</script>

<style lang="scss" scoped>

	@import '@/assets/scss/components/container';

</style>
